<template>
  <v-navigation-drawer
    v-model="toggleSidebar"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :width="200"
    hide-overlay
    app>

    <v-list class="pt-0" dense>

      <template v-for="item in list">

        <v-list-item
            :disabled="!!item.disabled"
          :key="item.label"
          :to="item.route">

          <v-list-item-action>
            <v-icon size="22px">{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </template>

    </v-list>

  </v-navigation-drawer>
</template>

<script>
  export default
  {
    name: "Sidebar",

    data()
    {
      return {

        list:
        [
          {icon: 'fas fa-tachometer-alt', route: {name: 'dashboard'}, label: this.$t('common.sidebar.dashboard')},
          {icon: 'fas fa-server', route: {name: 'subscriptions'}, label: this.$t('common.sidebar.subscriptions')},
          {icon: "fas fa-database", route: {name: "orders"}, label: this.$t('common.sidebar.orders')},
          {icon: "fas fa-users", route: {name: "developers-users"}, label: this.$t('common.sidebar.developers-users')}
        ]
      }
    },

    computed:
    {
      toggleSidebar:
      {
        get() {return this.$store.getters['sidebar/getToggle']},
        set(val) {this.$store.commit('sidebar/setToggle', val)}
      }
    }
  }
</script>

<style scoped>

</style>